export default function placeData() {
    return {
        showModal: false,
        selectedPlace: null,
        newComment: '',
        comments: [],
        isSubmitting: false,
        highlightedPlaceIndex: null,
        highlightedMarker: null,
        openIndex: null,
        places: window.placesData || [],
        selectedCategories: [],
        isFilterOpen: false,
        isLoading: true,
        viewMode: 'map',
        characterCount: 0,
        clickedPlaceIndex: null,
        get uniqueCategories() {
            return [...new Set(this.places.map(place => place.category.name))];
        },
        get filteredPlaces() {
            return this.selectedCategories.length > 0
                ? this.places.filter(place => this.selectedCategories.includes(place.category.name))
                : this.places;
        },
        truncateDescription(description) {
            return description.split(' ').slice(0, 5).join(' ') + (description.split(' ').length > 5 ? '...' : '');
        },
        updatePlaces(event) {
            this.places = event.detail;
            this.isLoading = false;
        },
        init() {
            const placesListEl = document.getElementById('placesList');
            if (placesListEl) {
                placesListEl.addEventListener('update-places', this.updatePlaces.bind(this));
            }

            document.addEventListener('map-bounds-changed', () => {
                this.$nextTick(() => {
                    this.$el.querySelector('.flex-wrap').innerHTML = this.$el.querySelector('.flex-wrap').innerHTML;
                });
            });

            this.$watch('showModal', (value) => {
                if (!value) {  // When modal is closed
                    this.clickedPlaceIndex = null;
                    this.clearHighlight();
                }
            });

            this.$watch('visibleCategories', (categories) => {
                Alpine.store('categoryData').visibleCategories = new Set(categories);
            });
        },
        toggleHighlightPlace(index) {
            // If this is the same place and it's not from a click (clickedPlaceIndex is null), do nothing
            if (this.highlightedPlaceIndex === index && this.clickedPlaceIndex === null) {
                return;
            }

            // Clear any existing highlight first
            this.clearHighlight();

            // Set new highlight
            this.highlightedPlaceIndex = index;
            const placeDiv = document.getElementById(`place-${index}`);
            if (placeDiv) {
                placeDiv.classList.add('highlighted');
            }

            // Find and animate the marker
            if (window.markers) {
                const markerObj = window.markers.find(m => m.originalIndex === index);

                if (markerObj && markerObj.marker) {
                    // Ensure any existing animation is stopped
                    if (typeof markerObj.marker.stopBouncing === 'function') {
                        markerObj.marker.stopBouncing();
                    }

                    // Start new animation
                    if (typeof markerObj.marker.bounce === 'function') {
                        markerObj.marker.bounce({ duration: 500, height: 20 });
                        this.highlightedMarker = markerObj.marker;
                    }
                }
            }
        },
        async fetchComments() {
            if (!this.selectedPlace) return;
            try {
                const response = await fetch(`/places/${this.selectedPlace.id}/check-ins`);
                if (!response.ok) throw new Error('Failed to fetch check-ins');
                const data = await response.json();

                // Update both the comments array and the selectedPlace check_ins
                this.comments = data;
                if (this.selectedPlace) {
                    this.selectedPlace.check_ins = data;
                }
            } catch (error) {
                console.error('Error fetching check-ins:', error);
            }
        },
        async submitCheckIn() {
            if (!this.selectedPlace ||
                this.isSubmitting ||
                this.characterCount < 25 ||
                this.characterCount > 500) return;

            this.isSubmitting = true;
            try {
                const response = await fetch(`/places/${this.selectedPlace.id}/check-ins`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    },
                    body: JSON.stringify({ comment: this.newComment })
                });

                if (!response.ok) {
                    throw new Error('Failed to submit check-in');
                }

                const newCheckIn = await response.json();

                // Initialize check_ins array if it doesn't exist
                if (!this.selectedPlace.check_ins) {
                    this.selectedPlace.check_ins = [];
                }

                // Add the new check-in to the beginning of the array
                this.selectedPlace.check_ins.unshift({
                    id: newCheckIn.id,
                    comment: newCheckIn.comment,
                    user_name: newCheckIn.user_name,
                    created_at: newCheckIn.created_at,
                    is_approved: true // Add this if you're using approval system
                });

                // Update check-ins count if it exists
                if (this.selectedPlace.check_ins_count !== undefined) {
                    this.selectedPlace.check_ins_count++;
                }

                // Update most recent check-in
                this.selectedPlace.most_recent_check_in = new Date().toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                });

                // Clear the form
                this.newComment = '';
                this.characterCount = 0;

                // Force Alpine to re-render the check-ins list
                this.$nextTick(() => {
                    this.selectedPlace = { ...this.selectedPlace };
                });

            } catch (error) {
                console.error('Error submitting check-in:', error);
                alert('Failed to submit check-in. Please try again.');
            } finally {
                this.isSubmitting = false;
            }
        },
        showModalAndFetchComments(place) {
            this.showModal = true;
            this.selectedPlace = place;
            this.newComment = '';
            this.characterCount = 0;

            // Find and store the index of the clicked place
            this.clickedPlaceIndex = this.places.findIndex(p => p.id === place.id);

            // Highlight the clicked place
            this.toggleHighlightPlace(this.clickedPlaceIndex);

            this.fetchComments();
        },
        toggleCategory(categoryId) {
            const index = this.selectedCategories.indexOf(categoryId);
            if (index === -1) {
                this.selectedCategories.push(categoryId);
            } else {
                this.selectedCategories.splice(index, 1);
            }
            // Update the global store
            Alpine.store('placeData').selectedCategories = this.selectedCategories;

            this.updateMapMarkers();
        },
        updateMapMarkers() {
            if (!window.markers || !window.map) {
                return;
            }

            const bounds = window.map.getBounds();
            const uniqueCategories = Array.from(new Set(
                window.placesData
                    .map(place => place.category)
                    .filter(Boolean)
            ));

            window.markers.forEach(({ marker, place }) => {
                const categoryIndex = uniqueCategories
                    .findIndex(cat => cat.name === place.category.name);

                // Check both category filter and map bounds
                const isInBounds = bounds.contains(marker.getLatLng());
                const isCategoryVisible = this.selectedCategories.length === 0 ||
                                        this.selectedCategories.includes(categoryIndex);

                if (isInBounds && isCategoryVisible && !window.map.hasLayer(marker)) {
                    window.map.addLayer(marker);
                } else if (!isInBounds || !isCategoryVisible) {
                    window.map.removeLayer(marker);
                }
            });
        },
        get visibleCategories() {
            if (!window.map || !window.markers) {
                return this.places
                    .map(place => place.category?.name)
                    .filter(Boolean);
            }

            const bounds = window.map.getBounds();
            const visibleCategories = new Set(
                window.markers
                    .filter(({ marker }) => bounds.contains(marker.getLatLng()))
                    .map(({ place }) => place.category?.name)
                    .filter(Boolean)
            );

            return Array.from(visibleCategories);
        },
        isPlaceVisible(index) {
            if (!window.map || !window.markers) return true;
            const marker = window.markers.find(m => m.originalIndex === index)?.marker;
            return marker && window.map.getBounds().contains(marker.getLatLng());
        },
        clearCategories() {
            this.selectedCategories = [];
            Alpine.store('placeData').selectedCategories = [];
            this.updateMapMarkers();
        },
        clearHighlight() {
            // Only clear if this isn't the clicked place
            if (this.highlightedPlaceIndex !== this.clickedPlaceIndex) {
                if (this.highlightedPlaceIndex !== null) {
                    const placeDiv = document.getElementById(`place-${this.highlightedPlaceIndex}`);
                    if (placeDiv) {
                        placeDiv.classList.remove('highlighted');
                    }

                    if (this.highlightedMarker && typeof this.highlightedMarker.stopBouncing === 'function') {
                        this.highlightedMarker.stopBouncing();
                        this.highlightedMarker = null;
                    }

                    this.highlightedPlaceIndex = null;
                }
            }
        }
    }
}
